import React from 'react'
import Layout from '../../../components/Global/Layout'
import Legal from '../../../components/Legal'
import { graphql } from 'gatsby'

const locale = 'en-CA'

export const query = graphql`
  {
    allContentfulTermsConditions {
      edges {
        node {
          id
          content {
            json
          }
          seoContent {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }
  }
`

const TermsConditions = (props) => {
  const { allContentfulTermsConditions } = props.data

  const termsConditionsData = allContentfulTermsConditions?.edges.filter(
    (node) => node.node.node_locale === locale
  )

  return (
    <Layout
      locale='en-CA'
      title={
        JSON.parse(termsConditionsData[0].node?.seoContent.internal.content)
          .title
      }
      desc={
        JSON.parse(termsConditionsData[0].node?.seoContent.internal.content)
          .desc
      }
    >
      <Legal title='Terms & Conditions' data={termsConditionsData} />
    </Layout>
  )
}

export default TermsConditions
